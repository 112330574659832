#mainContainer {
    min-height: 100vh;
}
#headerImgWrapper {
    width: 100%;
    background-image: url("../../assets/header/demenageur-min.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: white;
    height: 56vw;
    margin-top: -30px;
    padding-top: 30px;

    #titleSection {
        padding-top: 10%;
        margin-bottom: 4%;

        p,
        h1,
        h3 {
            color: white;
            text-align: center;
        }
    }

    #searchSection {
        text-align: center;
        color: white;

        input[type="text"] {
            font-family: MontRegular;
        }

        path {
            stroke: none;
            fill-rule: nonzero;
            fill: rgb(100%, 100%, 100%);
            fill-opacity: 1;
        }

        input {
            background-color: transparent;
        }

        ::placeholder {
            color: white;
        }

        .inputImg {
            background-color: transparent;
            color: white;
            border-width: 2px;
            border-right: none;
            height: 2.5em;
            padding: 0 0 0 5px;
            border-color: white;
        }

        .keywordSearch {
            color: white;
            border-width: 2px;
            border-left: none;
            border-top-right-radius: 0%;
            border-bottom-right-radius: 0%;
            height: 2.5em;
            min-width: 300px;
            border-color: white;
            &:focus {
                outline: 0 none !important;
                border-color: white;
                box-shadow: none;
                border-width: 2px;
            }
        }

        .postalSearch {
            color: white;
            border-color: white;
            border-width: 2px;
            border-top-left-radius: 0%;
            border-bottom-left-radius: 0%;
            border-left: none;
            margin-right: 5%;
            height: 2.5em;
            width: 75%;
            padding-right: 0;
            padding-left: 0;
            text-align: center;
            border-color: white;
            &:focus {
                outline: 0 none !important;
                border-color: white;
                box-shadow: none;
                border-width: 2px;
            }
        }

        .categoryFilters {
            &::-webkit-scrollbar {
                width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: lightgrey;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
            width: 45vw;
            margin: auto;
            margin-top: 1em;
            display: flex;
            justify-content: center;
            max-height: 95px;
            overflow: auto;
            p {
                margin-bottom: 0;
            }
            div {
                margin: 0 5px 10px 5px;
                border-radius: 4px;
                .category-btn {
                    text-align: center;
                    height: fit-content;
                    background: none;
                    border: 2px solid;
                    border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
                    border-image-slice: 1;
                    color: white;
                    transition: all 0.3s;
                    min-width: 140px;
                    height: 40px;
                    &:focus {
                        outline: 0;
                    }
                    &:hover {
                        transform: scale(0.92);
                        transition: all 0.3s;
                    }
                }
            }
        }
    }
}
.be_yourself {
    margin-top: 8em;
    margin-bottom: 15em;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    h2 {
        text-align: center;
    }
}
.be_your_boss {
    text-transform: none !important;
}
.active2 {
    box-shadow: 0 0 4px;
    transition: all 0.2s;

    button {
        border: 3px solid white;
        border-radius: 10px;
        &:focus {
            outline: 0;
        }
    }
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    top: 0;
}

.gm-style a img {
    filter: grayscale(1);
}

#offerWrapper {
    width: auto;
    padding: 5% 0px 0px 15px;
    min-height: calc(95vh - 80px);
    .offers {
        width: 100%;
        .list-group {
            min-height: 100vh;
        }
        .offer_category {
            color: gray;
        }
        .list-group-item-text {
            text-align: justify;
            width: 95%;
        }
        .offerInfos {
            width: 95%;
        }
        .categoryMapList {
            width: 100%;
            text-align: left;
        }
        .list-group-item {
            border: 1px solid lightgrey;
            margin-bottom: 10px;
            border-radius: 5px;
            transition: all 0.2s;
            padding: 0;
            .row {
                height: 100%;
                min-height: 215px;
                .col-sm-4 {
                    height: auto;
                    img {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                }
            }
            &:hover {
                transform: scale(1.01);
                cursor: pointer;
            }
            &.active2 {
                transform: scale(0.94);
            }
        }
        .listBanner {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .offer_infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .final_row {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 98%;
            .kwanukobutton {
                height: 30px;
            }
        }
    }
    .listBannerEmpty {
        background-color: lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .map {
        height: calc(95vh - 80px);
    }
    .mapcol {
        width: 50%;
        float: right;
        position: sticky;
        top: 80px;
        padding-right: 0px;
        .gm-style-iw.gm-style-iw-c {
            width: 400px;
            height: 300px;
        }
        .infoWindowImg {
            width: 100%;
            height: 130px;
            object-fit: cover;
            box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
        }
    }
}

#signUp {
    min-height: 700px;
    padding: 0 5% 0 5%;
    position: relative;
    p {
        padding: 0;
    }
    .man_pointing {
        position: absolute;
        bottom: -6px;
        left: 36%;
        z-index: 1;
        height: 40vh;
    }

    .row {
        display: flex;
        align-content: center;
        margin-top: 200px;
        padding: 80px 0 80px 0;
    }
    .imgWrapper {
        max-width: 50%;
        margin-top: -180px;
        img {
            width: 30vw;
            box-shadow: 0 0 10px;
        }
    }

    .txtWrapper {
        position: relative;
        top: 20px;
        z-index: 1000;
    }
    .ready_to_start {
        p {
            margin-bottom: 0;
            padding-left: 10px;
        }
    }
}

@media (max-width: 1260px) {
    #signUp {
        .man_pointing {
            bottom: -8px;
            left: 29%;
            transform: rotate(-1deg);
        }
    }
}

@media (max-width: 1100px) {
    #offerWrapper {
        .list-group-item-text {
            text-align: initial;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

@media (max-width: 900px) {
    #signUp {
        .man_pointing {
            bottom: -3px;
            left: 23%;
            transform: rotate(1deg);
        }
    }
}
@media (max-width: 700px) {
    #signUp {
        .imgWrapper {
            z-index: 1000;
        }
        .man_pointing {
            opacity: 0.2;
            z-index: 0;
            height: initial;
        }
        .row {
            display: block;
        }
        .txtWrapper a {
            margin: auto;
            text-align: center;
            display: block;
        }
    }
}
@media (max-width: 640px) {
    #signUp {
        .man_pointing {
            left: 10%;
        }
    }
}

@media (max-width: 1094px) {
    #headerImgWrapper {
        height: fit-content;
        background-image: none;
        #titleSection {
            background-image: url("../../assets/header/demenageur-min.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-color: white;
            height: 56vw;
            h1,
            h3 {
                padding: 4% 5% 0 5%;
                color: white;
                font-size: revert;
            }
        }

        #searchSection {
            color: black;
            path {
                fill: grey;
            }
            ::placeholder {
                color: grey;
            }
            .inputImg {
                color: grey;
                border-color: grey;
            }
            .keywordSearch {
                color: grey;
                border-color: grey;
                &:focus {
                    border-color: grey;
                }
            }
            .postalSearch {
                color: grey;
                border-color: grey;
                //margin-top: 0.5em;
                &:focus {
                    border-color: grey;
                }
            }
            .categoryFilters {
                div {
                    .category-btn {
                        color: black;
                    }
                }
            }
        }
    }
}

@media (max-width: 720px) {
    #headerImgWrapper {
        padding-top: initial;
        #searchSection {
            .kwanukobutton {
                height: 35px !important;
                margin-top: 1em;
            }
            .categoryFilters {
                margin-top: 2em;
                margin-bottom: 2em;
                width: initial;
            }
            .keywordSearch {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                min-width: 0;
            }
            .postalSearch {
                border-radius: 0.25rem;
                border-left-style: solid !important;
                margin-right: 0;
                border-left-width: 2px;
                margin-top: 10px;
            }
        }
    }
    #signUp {
        .imgWrapper {
            img {
                width: 42vw;
            }
        }
    }
    .mapcol {
        display: none;
    }
    #offerWrapper {
        .offers {
            padding-right: 3%;
        }
        .list-group-item {
            height: fit-content !important;
        }
    }
}

@media (max-width: 575px) {
    #offerWrapper {
        .offer_infos {
            padding-left: 6% !important;
        }
        .offers .list-group-item .row .col-sm-4 img {
            border-bottom-left-radius: 0;
            border-top-right-radius: 5px;
        }
    }
}
@media (max-width: 400px) {
    #offerWrapper {
        .offer_infos {
            padding-left: 2% !important;
        }
        .offers {
            .row {
                margin-left: 0 !important;
                margin-right: 0 !important;
                .col-sm-4,
                .col {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

@media (max-width: 330px) {
    #offerWrapper {
        .offers {
            .list-group-item-text {
                text-align: left;
            }
        }
        .offer_infos {
            .final_row {
                margin-top: 10px;
                display: block;
                text-align: center;
                width: 98%;
                p {
                    text-align: center;
                }
                .kwanukobutton {
                    height: 30px;
                    margin-top: 5px;
                }
            }
        }
    }
}
