.lightgrey {
    background-color: #eaeaea;
}

#footerWrapper {
    position: relative;
    width: 100%;
    height: 380px;
    background-image: url("../../assets/footer/elipsefooter-min.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    p,
    h2 {
        margin-bottom: 0;
        color: white;
    }

    .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 5%;
        a {
            text-transform: uppercase;
        }
        .footerContent {
            height: fit-content;
            padding: 0 10%;
            .centered {
                text-align: center;
            }
        }

        hr {
            border: 1px solid white;
            margin-bottom: 1rem;
            margin-top: 0;
        }

        .footerNav {
            width: 100%;
            height: 126px;
            padding: 0;

            .footerRight {
                display: inline-flex;
                align-items: center;
            }
        }

        a,
        a:link,
        a:visited,
        a:active {
            color: white;
            text-decoration: none;
            align-content: center;
            padding: 0 40px 0 0;
        }
        .copyrights {
            font-size: 16px;
            color: white;
        }
        .logoSocialMedia {
            a {
                padding: 0 0px 0 0;

                &:not(:first-child) {
                    margin-left: 15px;
                }

                img {
                    width: 25px;
                }
            }
        }
        .dragon {
            margin-bottom: -2px;
            width: 65px;
            margin-left: 25px;
        }
    }
}

@media (max-width: 1300px) {
    .footerNav {
        display: block !important;
        margin: auto;
        text-align: center;
        max-height: 100px;
    }
}
@media (max-width: 900px) {
    #footerWrapper {
        position: relative;
        background-image: none;
        background-color: #003a24;
        padding-top: 3em;
        padding-bottom: 3em;
        height: initial;
        .man_pointing {
            position: absolute;
            bottom: 489px;
            left: 12%;
            height: 40vh;
            transform: rotate(1deg);
        }
        .footer {
            position: relative;
            a {
                padding: 0 !important;
            }
            .animatedLink {
                margin: 10px 0 0 0;
                display: block;
            }
            .logoSocialMedia {
                display: flex;
                margin: auto;
                justify-content: center;
            }
            .kwanukobutton {
                margin-top: 1em;
            }
            .copyrights {
                margin: 0;
                color: white;
                margin-top: 2em;
                margin-bottom: 2em;
            }
            .footerNav {
                height: initial;
                max-height: initial;
            }
            .footerRight {
                display: block !important;
            }
        }
    }
    .footerContent {
        .row {
            display: block !important;
        }
    }
}

@media (max-width: 500px) {
    #footerWrapper {
        .man_pointing {
            bottom: 528px;
            left: -10%;
        }
    }
}
