.terms-conditions {
    margin-top: 10em;
    margin-left: 2em;
    margin-right: 2em;
    span {
        font-family: "MontBold";
    }
    p {
        font-family: "MontRegular" !important;
    }
    li {
        font-family: "MontRegular";
    }
}
