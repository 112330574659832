#userMainContainer {
    margin-top: 100px;
    min-height: 50vh;
    .react-tel-input .form-control {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        font-size: initial;
    }
    .react-tel-input .flag-dropdown {
        background-color: transparent;
        border: none;
    }
    .accept_form {
        text-align: left;
        width: 95%;
        margin: auto;
        label {
            font-family: "MontRegularItalic";
        }
        .cgu_link {
            text-decoration: underline;
            font-family: "MontRegularItalic";
            &:hover {
                text-decoration: underline !important;
                font-family: "MontRegularItalic";
                cursor: pointer;
                color: green !important;
            }
        }
    }
    .react-tel-input .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
        background-color: transparent;
    }
    .react-tel-input .flag-dropdown.open .selected-flag {
        background: transparent;
    }
    .tab_selected_mobile {
        display: none;
    }
    .tab_link {
        color: grey;
        cursor: pointer;
        &:hover {
            color: lightgrey;
        }
    }
    .success {
        color: green;
        padding-bottom: 2em;
        text-align: center;
    }
    .card_inputs {
        width: 290px;
        margin: auto;
    }
    .tab-content {
        min-height: 550px;
    }
    .supportContainerTitle {
        width: 70%;
        padding-bottom: 2em;
        .grey {
            color: grey;
        }
    }
    .supportContainer {
        form {
            width: 50%;
            margin: auto;
        }
    }
    .tab-pane {
        h4,
        h5 {
            width: 40%;
            margin: auto;
            padding-bottom: 1em;
            white-space: pre-line;
        }
    }
    .no_offer_container {
        width: 50%;
    }
    .no_offers {
        font-size: 20px;
        p {
            width: 40%;
            margin: auto;
        }
    }
    .changeemailrequest {
        p {
            margin: 2em auto 1em auto;
            width: 50%;
            text-align: center;
        }
        span.bold {
            text-align: center;
            font-family: "MontBold";
        }
        span.regular {
            font-family: "MontRegular";
            text-align: center;
        }
    }
    .form-control {
        border-radius: 0;
    }
    .newAdButton {
        text-align: center;
        display: block;
        margin-top: 2em;
        margin-left: auto;
        margin-right: auto;
    }
    .checkbox_cgu {
        display: flex;
    }
    .checkbox_cgu_address {
        label {
            display: flex;
            align-items: center;
            align-content: center;
        }
        display: flex;
        margin-left: -0.5em;
    }
    .checkbox_payment {
        display: flex;
        margin-bottom: 0;
        label {
            margin-bottom: 0;
        }
        .form-group {
            margin-bottom: 0;
        }
    }
    .form-text {
        margin-top: 0;
        margin-bottom: 1em;
    }
    .form-control:focus,
    .form-control.fullinput {
        border: 2px solid;
        border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
        border-image-slice: 1;
        outline: none !important;
    }
    input {
        border-radius: 0;
        .form-control:focus,
        .form-control.fullinput {
            border: 2px solid;
            border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
            border-image-slice: 1;
            outline: none !important;
        }
    }

    a:hover,
    a.active {
        font-family: "MontBold";
        color: black;
        text-decoration: none;
        align-content: center;
    }
    a:visited {
        text-decoration: none;
    }
    a {
        font-family: "MontRegular";
        color: black;
        text-decoration: none;
        align-content: center;
    }
    .nav-tabs {
        border: none;
    }
    .nav-item {
        outline: none;
    }
    .nav-link {
        border: none;
        transition: all 0.1s;
        &:hover {
            cursor: pointer;
            border-color: white;
            color: grey !important;
        }
        &:focus {
            outline: none;
            border: none;
        }
    }
    .nav-link.active {
        border-bottom: 3px solid #1b8837;
        border-width: 0 0 3px 0;
    }
    .infoContainer {
        padding: 3% 5% 5% 5%;
        width: 70%;
        margin: auto;
        label {
            margin-bottom: 0;
        }
        .col {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    textarea {
        min-height: 150px;
        max-height: 150px;
    }
    .paymentContainer {
        padding: 2% 5%;
        .expiry {
            display: flex;
            .col {
                padding-right: 0.5rem;
            }
        }
    }
    .updated {
        color: orange;
        font-family: "MontBold";
        width: 50%;
        margin: auto;
    }
    .offersContainerEmpty {
        display: block;
    }
    .offer_status {
        .badge {
            border-radius: 10px;
            color: white;
            font-family: "MontBold";
            display: flex;
            align-items: center;
            padding: 5px;
        }
    }
    .offersContainer {
        padding: 2% 5%;
        display: grid;
        // grid-template: "auto auto auto";
        grid-template: "auto";
        // justify-content: left;
        thead {
            font-family: MontSemiBold;
        }
        tbody {
            font-family: MontRegular;
        }
        .updateIcon:hover {
            cursor: pointer;
        }
        .deleteIcon:hover {
            cursor: pointer;
        }
        .card {
            // margin: 10px;
            margin: auto;
            max-width: 530px;
            transition: all 0.3s;
            border-radius: 0;
            border: none;
            box-shadow: 0 0 3px;
            margin-bottom: 3em;
            .card-img,
            .card-img-top {
                border-radius: 0;
            }
            .showonhover {
                background-color: transparent;
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 100%;
                height: 100%;
                transition: all 0.3s;
                div {
                    display: none;
                    transition: all 0.3s;
                }
            }
            &:hover {
                box-shadow: 0 0 10px;
                transition: all 0.3s;
                cursor: pointer;
                .showonhover {
                    background-color: rgba(0, 0, 0, 0.5);
                    div {
                        margin: 1em;
                        display: block;
                        &:hover {
                            transform: scale(1.4);
                            transition: all 0.3s;
                        }
                    }
                }
            }

            img {
                height: 160px;
                object-fit: cover;
            }
            .card-title {
                font-family: "MontBold";
            }
            .card-body {
                font-family: "MontRegular";
            }
        }
    }
    .borderBottom {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    .subCard {
        margin: 2em auto 2em auto;
        border: 2px solid;
        border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
        border-image-slice: 1;
        outline: none !important;
        border-radius: 0;
        box-shadow: 0 0 3px;
        width: 50%;
        font-family: "MontRegular";
        .card-header {
            background: none;
            text-align: center;
            font-size: 24px;
        }
        .card-body {
            text-align: center;
        }
        .card-footer {
            background: none;
        }
        .price {
            font-size: 40px;
            font-family: "MontBold";
        }
        .price_device {
            font-size: 20px;
            font-family: "MontSemiBold";
        }
        .d-block {
            font-style: italic;
        }
        .choice_buttons {
            text-align: center;
            button {
                margin: 10px;
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

.delete_offer_modal {
    .modal-content {
        border: 2px solid;
        border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
        border-image-slice: 1;
        outline: none !important;
        border-radius: 0;
    }
    .modal-content {
        font-family: "MontRegular";
        text-align: center;
    }
    .modal-footer {
        border: none;
        justify-content: center;
        padding-bottom: 1em;
    }
}
@media (max-width: 1300px) {
    .infoContainer {
        width: 100% !important;
    }
}

@media (max-width: 1100px) {
    #userMainContainer {
        .paymentContainer {
            .row {
                display: block;
                div {
                    margin: auto;
                }
                .col-4 {
                    margin-bottom: 1em;
                    max-width: initial;
                }
            }
            .expiry {
                .col {
                    padding-right: 0;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}
@media (max-width: 900px) {
    #userMainContainer {
        .tab-pane {
            h4,
            h5 {
                width: 100%;
                margin: auto;
                padding-bottom: 1em;
            }
        }
        .supportContainerTitle {
            width: 100%;
        }
        .offersContainer {
            display: block;
            .card {
                margin: 20px auto;
            }
        }
        .no_mobile {
            display: none;
        }
        .mobile_menu {
            display: block;
            .nav-item {
                margin: auto;
                text-align: center;
            }
        }
        .tab_selected_mobile {
            display: block;
            padding: 0 8px 0 1em;
            width: 95%;
            margin: auto;
            border: 1px solid grey;
            .tab_selected_mobile_content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            span {
                font-family: "MontBold";
            }
        }
        .nav-link {
            padding: 5px;
        }
        .nav-link.active {
            border: none;
        }
        .subCard {
            width: 70%;
        }
    }
}

@media (max-width: 750px) {
    #userMainContainer {
        .form-group {
            margin-bottom: 0;
            .row {
                display: block;
            }
            .col {
                margin-top: 0.6em;
            }
        }
        .supportContainerTitle {
            width: 100%;
            padding-bottom: 0em;
            .grey {
                color: grey;
            }
        }
        .supportContainer {
            form {
                width: 80%;
                margin: auto;
            }
        }
    }
}

@media (max-width: 700px) {
    #userMainContainer {
        .paymentContainer {
            .col-4,
            .col-8 {
                max-width: 100%;
            }
        }
        .no_offers {
            font-size: 20px;
            p {
                width: 90%;
                margin: auto;
            }
        }
        .infoContainer {
            margin-bottom: 3em;
        }
        .subCard {
            width: 95%;
        }
    }
}

.checkbox {
    --background: #fff;
    --border: green;
    --border-hover: #bbc1e1;
    --border-active: green;
    --tick: #fff;
    position: relative;
    input,
    svg {
        width: 18px;
        height: 18px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow 0.3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }

    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: 0.4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
        }
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce 0.4s linear forwards 0.2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
