#loginContainer {
    #formWrapper {
        position: relative;
        width: 50%;
        height: fit-content;
        margin: auto;
        top: 80px;
        margin-bottom: 100px;
        padding: 3% 5% 5% 5%;
        .facebookConnect {
            background-color: #1778f2;
            background-image: none;
            transition: all 0.3s;
            color: white;
            border: none;
            height: 40px;
            padding-top: 3px;
            border-radius: 10px;
            &:hover {
                transform: scale(0.92);
                transition: all 0.3s;
            }
        }
        .registerLink {
            color: black !important;
            font-size: 14px;
            font-family: "MontRegular";
            margin-top: 1em;
            &:hover {
                color: grey !important;
            }
        }
        .forgot {
            font-family: "MontRegular";
            display: flex;
            justify-content: space-between;
            span {
                font-size: 14px;
                color: black !important;
                &:hover {
                    color: grey !important;
                    cursor: pointer;
                }
            }
        }
        .loginForm {
            width: 70%;
            margin: auto;
        }
        hr {
            border: 1px solid #d3d3d3;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        .labelWithText {
            margin-bottom: 0;
        }

        small {
            color: black !important;
            margin-top: 0;
            font-family: MontSemiBold !important;
        }
        .form-control {
            border-radius: 0;
        }
        .form-control:focus,
        .form-control.fullinput {
            border: 2px solid;
            border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
            border-image-slice: 1;
            outline: none !important;
        }
        textarea {
            min-height: 150px;
            max-height: 150px;
        }
    }

    .signUpTitle {
        .logo {
            position: relative;
            bottom: -4px;
            width: 10%;
            height: auto;
        }
    }
}
.success_confirm {
    color: green;
    padding-bottom: 2em;
    text-align: center;
}
.error_confirm {
    color: orange;
    text-align: center;
    padding-bottom: 2em;
}
@media (max-width: 632px) {
    #loginContainer {
        #formWrapper {
            .loginForm {
                width: 100%;
            }
        }
    }
}
@media (max-width: 580px) {
    .headerRight {
        p {
            display: none;
        }
    }
}
