#offerFormContainer {
    #formWrapper {
        input, select {
            font-family: "MontRegular";
        }
        position: relative;
        width: 70%;
        height: fit-content;
        margin: auto;
        top: 80px;
        margin-bottom: 100px;
        padding: 3% 5% 5% 5%;
        hr {
            border: 1px solid lightgrey;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }
        .checkbox_cgu_address {
            label {
                display: flex;
                align-content: center;
            }
            display: flex;
            margin-left: -0.5em;
        }
        .bannerImageMain div:first-of-type:hover,
        .bannerImageSecond div:first-of-type:hover:hover {
            cursor: pointer;
            transition: 0.1s all;
            color: grey;
        }
        .bannerImageMain div:first-of-type,
        .bannerImageSecond div:first-of-type {
            transition: 0.1s all;
        }
        .labelWithText {
            margin-bottom: 0;
        }
        small {
            color: black !important;
            margin-top: 0;
            font-family: MontSemiBold !important;
        }
        .form-control {
            border-radius: 0;
        }
        .form-control:focus,
        .form-control.fullinput {
            border: 2px solid;
            border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
            border-image-slice: 1;
            outline: none !important;
        }
        textarea {
            min-height: 150px;
            max-height: 150px;
        }
        .form-group .col {
            padding-left: 5px;
            padding-right: 5px;
        }
        .registerButtonWrapper {
            text-align: center;
        }
        .form-check-input {
            position: relative;
            margin-left: 0;
        }
        // .tox-edit-area {
        //     border: 2px solid;
        //     border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
        //     border-image-slice: 1;
        //     outline: none !important;
        // }
    }
}

@media (max-width: 500px) {
    #formWrapper {
        .form-group {
            margin-bottom: 0;
            .row {
                display: block;
            }
            .col {
                margin-top: 0.6em;
            }
        }
    }
}
