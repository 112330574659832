.headerContainer {
    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 0 6px 4px -1px rgba(0, 0, 0, 0.25);
    position: fixed !important;
    z-index: 10000;
    top: 0;
    .languageChoice {
        padding: 0 0 0 20px;
    }
    .logo {
        width: auto;
        height: 57px;
    }
    .logomobile {
        display: none;
    }
    .dropdown-toggle::after {
        content: initial;
    }
    .btn-secondary,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle,
    .btn-secondary.focus,
    .btn-secondary:focus,
    .btn-secondary:hover,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
        background-color: transparent;
        border: none;
        transform: scale(0.9);
        transition: all 0.3s;
        box-shadow: none;
    }
    .dropdown-toggle:hover {
        transform: scale(0.8);
    }
    .dropdown-menu {
        min-width: 12rem;
        padding: 0;
    }
    .dropdown-divider {
        margin: 0;
    }
    .dropdown-item {
        padding: 0.25rem 0.5rem;
    }
    .dropdown-item:hover {
        background-color: lightgrey;
    }
    .dropdown-item.active,
    .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: lightgrey;
    }
    .dropdown-menu.show {
        border: 2px solid;
        border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
        border-image-slice: 1;
        outline: none !important;
    }
    .dropdown-name {
        color: black;
        font-family: "MontSemiBold";
        margin-right: 10px;
    }
    .btn:focus {
        box-shadow: none;
    }
    .headerRight {
        display: inline-flex;
        align-items: center;
        text-align: end;
        p {
            padding: 0 40px 0 0;
        }
    }
}

a:visited,
a:hover,
a:active {
    color: black !important;
    text-decoration: none !important;
}
@media (max-width: 992px) {
    #mainContainer {
        #headerImgWrapper {
            margin-top: 30px;
        }
    }
}
@media (max-width: 770px) {
    .headerContainer {
        .logo {
            display: none;
        }
        .logomobile {
            display: block !important;
            width: auto;
            height: 45px;
        }
        .dropdown-toggle {
            padding: 0 !important;
        }
    }
}

@media (max-width: 465px) {
    .headerContainer {
        flex-wrap: nowrap !important;

        .headerRight {
            p {
                padding-right: 10px;
            }
            a {
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 370px) {
    .headerContainer {
        .languageChoice {
            padding: 0 0 0 00px;
        }
        .headerRight {
            display: flex;
            align-content: center;
        }
        .dropdown-name {
            display: none;
        }
    }
}
