#passwordResetContainer {
    #formWrapper {
        position: relative;
        width: 50%;
        height: fit-content;
        margin: auto;
        top: 80px;
        margin-bottom: 100px;
        padding: 3% 5% 5% 5%;

        min-height: 640px;
        .registerLink {
            color: black !important;
            font-size: 14px;
            font-family: "MontRegular";
            margin-top: 1em;
            &:hover {
                color: grey !important;
            }
        }
        .forgot {
            font-family: "MontRegular";
            display: flex;
            justify-content: space-between;
            a {
                font-size: 14px;
                color: black !important;
                &:hover {
                    color: grey !important;
                }
            }
        }
        .expired_token {
            font-family: "MontBold";
            color: orange;
            text-align: center;
            display: block;
            width: 75%;
            margin: auto;
            margin-bottom: 2em;
        }
        .resetForm {
            width: 70%;
            margin: auto;
        }
        hr {
            border: 1px solid #d3d3d3;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        .labelWithText {
            margin-bottom: 0;
        }

        small {
            color: black !important;
            margin-top: 0;
            font-family: MontSemiBold !important;
        }
        .form-control {
            border-radius: 0;
        }
        .form-control:focus,
        .form-control.fullinput {
            border: 2px solid;
            border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
            border-image-slice: 1;
            outline: none !important;
        }
        textarea {
            min-height: 150px;
            max-height: 150px;
        }
    }

    .signUpTitle {
        .logo {
            position: relative;
            bottom: -4px;
            width: 10%;
            height: auto;
        }
    }
}
@media (max-width: 650px) {
    #passwordResetContainer {
        #formWrapper {
            min-height: initial;
            .resetForm {
                width: 100%;
            }
        }
    }
}
@media (max-width: 499px) {
    #passwordResetContainer {
        #formWrapper {
            min-height: initial;
        }
    }
}
