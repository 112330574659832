label {
    font-family: MontBold;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea,
button {
    font-family: MontRegular !important;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
    padding-top: 8px;
}   
#signUpContainer #formWrapper .form-control {
    font-family: 'MontRegular';
}
#signUpContainer {
    min-height: 70vh;
    .react-tel-input .form-control {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        font-size: initial;
    }
    .react-tel-input .flag-dropdown {
        background-color: transparent;
        border: none;
    }
    .react-tel-input .selected-flag:hover,
    .react-tel-input .selected-flag:focus {
        background-color: transparent;
    }
    .react-tel-input .flag-dropdown.open .selected-flag {
        background: transparent;
    }
    #formWrapper {
        position: relative;
        width: 70%;
        height: fit-content;
        margin: auto;
        top: 80px;
        margin-bottom: 100px;
        padding: 3% 5% 5% 5%;

        hr {
            border: 1px solid lightgrey;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        .labelWithText {
            margin-bottom: 0;
        }

        small {
            color: black !important;
            margin-top: 0;
            font-family: MontSemiBold !important;
        }
        .form-control {
            border-radius: 0;
        }
        .form-control:focus,
        .form-control.fullinput {
            border: 2px solid;
            border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
            border-image-slice: 1;
            outline: none !important;
        }
        textarea {
            min-height: 150px;
            max-height: 150px;
        }
        .form-group .col {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .signUpTitle {
        .logo {
            position: relative;
            bottom: -4px;
            width: 10%;
            height: auto;
        }
    }

    .registerButtonWrapper {
        text-align: center;
    }
}

@media (max-width: 500px) {
    #formWrapper {
        .form-group {
            margin-bottom: 0;
            .row {
                display: block;
            }
            .col {
                margin-top: 0.6em;
            }
        }
    }
}
