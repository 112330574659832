a {
    color: black;
    outline: none;
}
.offerPagination {
    width: 50%;
}
.pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    align-items: center;
    margin: auto;
    height: 50px;
    .active {
        background: linear-gradient(white, white) padding-box, linear-gradient(180deg, #24b6b5 46%, #2ad356 100%);
        border: 2px solid transparent;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;

        a {
            font-family: "MontRegular";
            border-radius: 100%;
        }

        a:hover {
            color: seagreen !important;
        }
    }

    li.disabled {
        display: none;
    }
    a:hover {
        text-decoration: underline !important;
    }
    .previous,
    .next {
        a {
            margin-top: -3px;
        }
    }
    .previous,
    .next {
        display: inline-flex;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.08);
        outline: none;
        margin: 0px;
        padding: 0px;
        color: rgb(34, 34, 34);
        background-color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        touch-action: manipulation;
        align-items: center;
        justify-content: center;
        background-clip: padding-box;
        box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s,
            transform 0.25s ease 0s;
        width: 32px;
        height: 32px;
        &:hover {
            border-color: rgba(0, 0, 0, 0.08);
            background-color: rgb(255, 255, 255);
            color: rgb(0, 0, 0);
            box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px;
            transform: scale(1.04);
        }
    }
}

@media (max-width: 720px) {
    .offerPagination {
        width: 100%;
        margin: auto;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
        width: 100% !important;
    }
}
