.landingContent {
    .landingTable {
        padding-right: 14vw;
        margin-top: 5%;
        width: 100%;
    }
    .coming_soon {
        img,
        p:not(.coming_soon_text) {
            opacity: 0.3;
        }
    }
    .coming_soon_text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        height: 100%;
        text-align: center;
        align-self: center;
        width: 100%;
        font-family: "MontBold";
        font-size: 1.5vw;
    }
    p {
        font-size: 1.3vw;
        margin-bottom: 0;
    }

    .bullet-group {
        padding-top: 20%;
    }

    .bullet {
        width: 1.5vw;
        height: auto;
        margin: 1vh 0.7vw;
        display: inline-block;
        border-radius: 100px;
    }

    .vertical-bullet-group {
        text-align: -webkit-center;
    }

    .vertical-bullet {
        width: 1.5vw;
        height: auto;
        display: block;
        border-radius: 1000px;
    }

    .iconWrapper {
        height: 7vw;
        padding-bottom: 2%;
        position: relative;
        .icon {
            padding: 0 1%;
            height: 95%;
            width: auto;
            margin-top: 8px;
        }
    }
    .final-text-col {
        margin: auto;
        margin-top: 2em;
        .final-text {
            font-size: 1.8vw;
            margin-left: -50px;
            margin-right: -50px;
        }
    }
}
.titleSectionMobile {
    display: none;
}
.titleSectionContent {
    margin-top: 4vw;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
#preFooter {
    position: relative;
    padding-bottom: 5%;
    margin-top: 5%;

    h4 {
        padding: 5% 28%;
    }

    .madameIcon {
        position: absolute;
        bottom: -38px;
        left: 16%;
        z-index: 1;
        height: 300px;
    }
}

@media (max-width: 992px) {
    .titleSectionContent {
        display: none;
    }
    .titleSectionMobile {
        display: block;
        margin-top: 20px;
        h1,
        h3 {
            font-size: x-large;
            padding: 0 !important;
            width: 85%;
            margin: auto;
            margin-bottom: 2em;
        }
    }
}

@media (max-width: 767px) {
    .landingContent {
        .bullet-group {
            padding-top: 0;
            padding-left: 47%;
        }

        .bullet {
            width: 20px;
            margin: 1rem 0.7vw;
            display: block;
        }

        .vertical-bullet {
            width: 20px;
        }

        .landingTable {
            padding-right: 0%;
        }

        p,
        .coming_soon_text {
            font-size: revert;
            padding: 0 5%;
        }

        .final-text-col {
            margin: auto;
            .final-text {
                font-size: revert;
                padding: 5% 10%;
                margin: initial;
            }
        }

        .iconWrapper {
            height: 100px;
            padding-bottom: 2%;
            .icon {
                padding: 0 1%;
                height: 100%;
                width: auto;
            }
        }
    }
}

@media (max-width: 960px) {
    .landingContent {
        .remove-tablet {
            display: none;
        }
    }
}

@media (max-width: 900px) {
    #preFooter {
        .madameIcon {
            position: absolute;
            bottom: -5px;
            left: 2%;
            z-index: 1;
            height: 300px;
            transform: rotate(10deg);
        }
        padding: 3em 0 14em;
        h4 {
            width: 90%;
            z-index: 2;
            margin: auto;
            padding: initial;
        }
        button {
            margin-top: 3em;
        }
    }
}
@media (max-width: 500px) {
    #preFooter {
        padding: 3em 0 20em;
    }
}
@media (min-width: 2000px) {
    #preFooter {
        .madameIcon {
            position: absolute;
            bottom: -50px;
            left: 16%;
            z-index: 1;
            height: 300px;
            transform: rotate(4deg);
        }
    }
}
