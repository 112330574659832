#offerPageContainer {
    position: relative;
    top: 100px;
    padding: 0 2%;
    position: relative;
    top: 100px;
    padding: 0 2%;
    padding-bottom: 100px;

    .btn.disabled,
    .btn:disabled {
        opacity: 0.65;
    }
    path {
        stroke: none;
        fill-rule: nonzero;
        fill: rgb(16.470589%, 82.745099%, 33.725491%);
        fill-opacity: 1;
    }

    //make this section nicer
    .offerTitle {
        display: flex;

        .titleImg {
            width: 250px;
            height: 250px;
            object-fit: cover;
        }
        .offer_details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .titleText {
            display: inline-block;
            vertical-align: middle;

            .dollarIcon {
                height: 1.3em;
                width: auto;
            }
        }
    }

    .offerDescription {
        padding-top: 2%;
        margin-bottom: 2em;
        a {
            color: #007bff !important;
        }
    }

    //make this section nicer
    .offerImages {
        padding: 2% 0;

        .carousel {
            max-width: 100%;
            height: 500px;
            text-align: center;
        }

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            filter: invert(1);
        }

        .carousel-item > img {
            height: 100%;
            width: auto;
            box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
        }
    }
}
.messagesend {
    font-family: "MontRegular";
    color: green;
    display: block;
    font-size: 16px;
    padding-top: 2em;
    margin: auto;
    width: 50%;
    text-align: center;
}
.messagenotsend {
    font-family: "MontRegular";
    color: orange;
    display: block;
    font-size: 16px;
    padding-top: 2em;
    margin: auto;
    width: 30%;
    text-align: center;
}
.offerContactForm {
    width: 100%;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    padding-top: 3em;
    padding-bottom: 3em;

    form {
        padding: 0 25% 0 25%;

        input[type="text"],
        textarea {
            background: linear-gradient(#eaeaea, #eaeaea) padding-box,
                linear-gradient(180deg, #24b6b5 46%, #2ad356 100%);
            border: 2px solid transparent;
            border-radius: 5px;
            margin: 2% 0%;
        }
        textarea {
            min-height: 200px;
        }
    }

    h3 {
        text-align: center;
    }
}
.woman_writing {
    position: absolute;
    bottom: -90px;
    right: 15%;
    z-index: 1;
    height: 450px;
    transform: rotate(8deg);
}
@media (max-width: 900px) {
    .offerContactForm {
        z-index: 1000;
        overflow: hidden;
        .woman_writing {
            position: absolute;
            right: -2%;
            z-index: 1;
            height: 450px;
            bottom: -62px;
            transform: initial;
            opacity: 0.2;
        }
        form {
            padding: 0 5em;
        }
    }
}

@media (max-width: 830px) {
    #offerPageContainer {
        margin-left: 15px;
        margin-right: 15px;
        h1 {
            font-size: 30px;
        }
        h2 {
            font-size: 26px;
        }
        h3 {
            font-size: 22px;
        }
        .offerTitle {
            display: block;
            .titleText {
                margin-left: 0 !important;
                margin-top: 2em;
            }
        }
        .offerDescription {
            margin-top: 2em;
            margin-bottom: 2em;
            .offerDescriptionText {
                text-align: justify;
            }
        }
        .offerImages {
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }
}

@media (max-width: 520px) {
    .offerContactForm {
        z-index: 1000;
        overflow: hidden;
        .woman_writing {
            opacity: 0.2;
        }
        form {
            padding: 0 1em;
        }
    }
}

@media (min-width: 2150px) {
    .woman_writing {
        position: absolute;
        bottom: -100px;
        right: 15%;
        z-index: 2;
        height: 450px;
        transform: rotate(5deg);
    }
}
