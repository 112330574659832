@font-face {
    font-family: MontHeavy;
    src: url(../assets/fonts/Mont-Heavy.otf);
}

@font-face {
    font-family: MontBold;
    src: url(../assets/fonts/Mont-Bold.otf);
}

@font-face {
    font-family: MontRegular;
    src: url(../assets/fonts/Mont-Regular.otf);
}

@font-face {
    font-family: MontRegularItalic;
    src: url(../assets/fonts/Mont-RegularItalic.otf);
}

@font-face {
    font-family: MontSemiBold;
    src: url(../assets/fonts/Mont-SemiBold.otf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: MontBold;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: inset 0 -1px 0 #ddd;
}
p {
    font-family: MontRegular;
}
.animatedLink {
    transition: all 0.3s;
    color: black;
    &:hover {
        transform: scale(0.95);
        transition: all 0.3s;
    }
    &:visited {
        color: black;
    }
}
input:focus {
    outline: 0 none;
}
.errormessagesmall {
    font-family: "MontRegular";
    color: orange;
    display: block;
    font-size: 13px;
}
.confirmationmessagesmall {
    font-family: "MontRegular";
    color: green;
    display: block;
    font-size: 14px;
}
.kwanukobuttonbigger {
    background: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
    border: none;
    border-radius: 4px;
    color: white;
    transition: all 0.3s;
    min-width: 280px;
    height: 48px;
    font-family: "MontSemiBold" !important;
    text-transform: uppercase;
    padding: 0 15px 0 15px;
    &:focus {
        outline: 0;
    }
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s;
    }
    &.loading {
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(180deg, rgba(36, 182, 181, 1) 46%, rgba(42, 211, 86, 1) 100%);
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 0.3s;
        color: grey;
    }
}
.kwanukobutton {
    background: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
    border: none;
    border-radius: 4px;
    color: white;
    transition: all 0.3s;
    min-width: 130px;
    height: 40px;
    font-family: "MontSemiBold" !important;
    text-transform: uppercase;
    &:focus {
        outline: 0;
    }
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s;
    }
    &.loading {
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(180deg, rgba(36, 182, 181, 1) 46%, rgba(42, 211, 86, 1) 100%);
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 0.3s;
        color: grey;
    }
}
textarea.form-control::placeholder {
    color: lightgrey;
    opacity: 1; /* Firefox */
}

input.form-control::placeholder {
    color: lightgrey;
    opacity: 1; /* Firefox */
}

input.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: lightgrey;
}

input.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
}
.kwanukobuttonlang {
    background: white;
    border: none;
    color: black;
    transition: all 0.3s;
    height: 30px;
    font-family: "MontRegular";
    text-transform: uppercase;
    &:focus {
        outline: 0;
    }
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s;
    }
    &.loading {
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(180deg, rgba(36, 182, 181, 1) 46%, rgba(42, 211, 86, 1) 100%);
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 0.3s;
        color: grey;
    }
}
.montbold {
    font-family: "MontBold" !important;
}
.kwanukobuttoncancel {
    background: lightgrey;
    border: none;
    border-radius: 4px;
    color: white;
    transition: all 0.3s;
    min-width: 130px;
    height: 40px;
    font-family: "MontSemiBold" !important;
    text-transform: uppercase;
    &:focus {
        outline: 0;
    }
    &:hover {
        transform: scale(0.9);
        transition: all 0.3s;
    }
    &.loading {
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(180deg, rgba(36, 182, 181, 1) 46%, rgba(42, 211, 86, 1) 100%);
        border: 2px solid transparent;
        border-radius: 4px;
        transition: all 0.3s;
        min-width: 140px;
        height: 40px;
    }
}
body {
    background-color: white;
}
.italic {
    font-family: "MontRegularItalic";
}
.bold {
    font-family: "MontBold";
}
.heavyBold {
    font-family: "MontHeavy";
}

//Category circle colours:

.dot {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    margin-top: 2px;
}

.Darkgreen {
    background-color: #2f4f2f;
}

.Darkseagreen {
    background-color: #8fbc8f;
}

.Sgichartreuse {
    background-color: #71c671;
}

.Forestgreen {
    background-color: #228b22;
}

.Lightgreen {
    background-color: #90ee90;
}

.Dumpster {
    background-color: #004f00;
}

.Green4 {
    background-color: #008b00;
}

.Green2 {
    background-color: #00ee00;
}

.Wasabi {
    background-color: #66ff66;
}

.Offwhitegreen {
    background-color: #ccffcc;
}

.Viridianlight {
    background-color: #6eff70;
}

.Gummigreen {
    background-color: #24d330;
}

.Greentaxi {
    background-color: #3f9e4d;
}

.Mintgreen {
    background-color: #bdfcc9;
}

.Seagreen {
    background-color: #2e8b57;
}

.Seagreen1 {
    background-color: #54ff9f;
}

.Mediumseagreen {
    background-color: #3cb371;
}

.Springgreen4 {
    background-color: #008b45;
}

.Springgreen {
    background-color: #00ff7f;
}

.Freshgreen {
    background-color: #5eda9e;
}

// MEDIA QUERIES

@media (max-width: 1300px) {
    #formWrapper {
        width: 100% !important;
    }
}
