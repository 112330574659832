#forgotContainer {
    font-family: "MontRegular";
    padding: 2em;
    border: 2px solid white;
    border-radius: 0 !important;
    .modal_header {
        text-align: center;
        hr {
            border: 1px solid #d3d3d3;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
    }
    .labelWithText {
        margin-bottom: 0;
    }
    .form-control {
        border-radius: 0;
    }
    .form-control:focus,
    .form-control.fullinput {
        border: 2px solid;
        border-image-source: linear-gradient(180deg, rgba(42, 211, 86, 1) 46%, rgba(36, 182, 181, 1) 100%);
        border-image-slice: 1;
        outline: none !important;
    }
}
.modal.fade.show {
    display: flex !important;
    align-items: center;
}
